import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
	cursor: pointer;

	margin: 0 auto;
	padding: 0 20px;

	display: block;

	width: calc(100% - 40px);
	height: auto;
	max-width: 360px;

	& path {
		transition: 500ms color ease-in-out;
		fill: ${(props) => props.fill};
	}

	@media (max-width: 500px) {
		max-width: 240px;
	}

	@media (max-width: 360px) {
		max-width: 200px;
	}
`;

export const StraplineIcon = ({ fill }) => (
	<Icon
		width="416"
		height="34"
		viewBox="0 0 416 34"
		fill={fill}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.1998 27.3037C18.9378 27.3037 23.0798 24.1117 24.4098 19.0957L20.9518 18.3737C20.0018 22.0597 17.3038 24.1877 13.1618 24.1877C7.76579 24.1877 3.85179 20.5017 3.85179 13.6997C3.85179 6.8977 7.76579 3.1737 13.1618 3.1737C17.3038 3.1737 20.1538 5.4157 20.9898 9.2537L24.4098 8.4937C23.3078 3.3637 19.0138 0.0957031 13.1998 0.0957031C6.54979 0.0957031 0.279785 4.5417 0.279785 13.6997C0.279785 22.8577 6.51179 27.3037 13.1998 27.3037Z"
			fill={fill}
		/>
		<path
			d="M37.0967 27.3037C42.7587 27.3037 46.8627 23.1617 46.8627 17.4237C46.8627 11.6857 42.7587 7.5437 37.0967 7.5437C31.3967 7.5437 27.3687 11.6857 27.3687 17.4237C27.3687 23.1617 31.3967 27.3037 37.0967 27.3037ZM37.0967 24.4157C33.2587 24.4157 30.6747 21.5277 30.6747 17.4237C30.6747 13.3197 33.2587 10.4317 37.0967 10.4317C40.9347 10.4317 43.5187 13.3197 43.5187 17.4237C43.5187 21.5277 40.9347 24.4157 37.0967 24.4157Z"
			fill={fill}
		/>
		<path
			d="M50.9961 26.9997H54.2261V18.6017C54.2261 13.5477 56.5061 10.4697 60.0781 10.4697C62.7761 10.4697 64.4101 12.3317 64.4101 14.9157V26.9997H67.6401V14.3077C67.6401 10.2417 64.7901 7.5437 60.8001 7.5437C57.8741 7.5437 55.5561 9.1777 54.2261 11.4577V7.8477H50.9961V26.9997Z"
			fill={fill}
		/>
		<path
			d="M82.3696 26.8477L82.1416 23.8837C81.3816 24.2637 80.5076 24.3777 79.7476 24.3777C78.0756 24.3777 76.9356 23.3897 76.9356 21.3757V10.6977H82.0656V7.8477H76.9356V2.0337H73.7056V7.8477H70.1336V10.6977H73.7056V21.2617C73.7056 25.4797 76.0996 27.3037 79.3676 27.3037C80.3936 27.3037 81.4196 27.1897 82.3696 26.8477Z"
			fill={fill}
		/>
		<path
			d="M102.984 16.5497C102.984 11.6097 99.4883 7.5437 93.8263 7.5437C88.0503 7.5437 84.5543 11.9897 84.5543 17.4237C84.5543 22.8577 88.1643 27.3037 93.9023 27.3037C98.3103 27.3037 101.502 25.1377 102.452 21.4137L99.4123 20.6917C98.8423 23.0857 96.8283 24.4537 93.9023 24.4537C90.4443 24.4537 88.0123 21.9077 87.7843 18.0317H102.908C102.946 17.6897 102.984 17.0057 102.984 16.5497ZM87.9743 15.3337C88.6203 12.1417 90.8243 10.2417 93.8263 10.2417C97.2083 10.2417 99.2603 12.4077 99.5643 15.3337H87.9743Z"
			fill={fill}
		/>
		<path
			d="M107.143 26.9997H110.373V17.3857C110.373 13.3197 112.159 10.3937 115.427 10.3937C118.087 10.3937 119.569 12.1797 119.569 14.8017V26.9997H122.799V17.3857C122.799 13.3197 124.585 10.3937 127.853 10.3937C130.551 10.3937 132.033 12.1797 132.033 14.8017V26.9997H135.263V14.1557C135.263 10.1277 132.679 7.5437 128.575 7.5437C125.687 7.5437 123.407 9.2917 122.381 11.5717C121.697 8.9877 119.265 7.5437 116.149 7.5437C113.223 7.5437 111.323 9.1777 110.373 11.4577V7.8477H107.143V26.9997Z"
			fill={fill}
		/>
		<path
			d="M140.504 33.9157H143.734V24.3397C145.026 26.0877 147.534 27.3037 149.966 27.3037C155.856 27.3037 159.2 23.0477 159.2 17.4237C159.2 11.7997 155.932 7.5437 150.042 7.5437C147.268 7.5437 144.76 8.8737 143.734 10.6217V7.8477H140.504V33.9157ZM149.662 24.4157C145.938 24.4157 143.468 21.6417 143.468 17.4237C143.468 13.2057 145.938 10.4317 149.662 10.4317C153.462 10.4317 155.932 13.2057 155.932 17.4237C155.932 21.6417 153.462 24.4157 149.662 24.4157Z"
			fill={fill}
		/>
		<path
			d="M171.952 27.3037C177.614 27.3037 181.718 23.1617 181.718 17.4237C181.718 11.6857 177.614 7.5437 171.952 7.5437C166.252 7.5437 162.224 11.6857 162.224 17.4237C162.224 23.1617 166.252 27.3037 171.952 27.3037ZM171.952 24.4157C168.114 24.4157 165.53 21.5277 165.53 17.4237C165.53 13.3197 168.114 10.4317 171.952 10.4317C175.79 10.4317 178.374 13.3197 178.374 17.4237C178.374 21.5277 175.79 24.4157 171.952 24.4157Z"
			fill={fill}
		/>
		<path
			d="M197.062 11.0017L197.252 7.8857C196.606 7.6577 195.846 7.5437 195.048 7.5437C192.692 7.5437 190.184 9.2917 189.082 11.7237V7.8477H185.852V26.9997H189.082V19.7037C189.082 13.4717 191.552 10.5457 194.592 10.5457C195.428 10.5457 196.34 10.6977 197.062 11.0017Z"
			fill={fill}
		/>
		<path
			d="M206.875 7.5437C202.847 7.5437 199.693 10.0137 199.123 13.8137L202.125 14.3837C202.391 11.7997 204.253 10.2417 206.875 10.2417C209.687 10.2417 211.549 11.9137 211.549 14.5357V14.9157L207.825 15.3337C201.707 15.9417 198.704 17.9557 198.704 21.6417C198.704 24.9477 201.327 27.3037 205.165 27.3037C207.939 27.3037 210.447 25.9357 211.549 23.7317V26.9997H214.741V14.7257C214.741 10.2797 211.511 7.5437 206.875 7.5437ZM205.659 24.6437C203.455 24.6437 201.935 23.3897 201.935 21.6037C201.935 19.5897 203.949 18.2597 207.825 17.8417L211.549 17.4237V18.3357C211.549 22.1737 209.383 24.6437 205.659 24.6437Z"
			fill={fill}
		/>
		<path
			d="M231.202 11.0017L231.392 7.8857C230.746 7.6577 229.986 7.5437 229.188 7.5437C226.832 7.5437 224.324 9.2917 223.222 11.7237V7.8477H219.992V26.9997H223.222V19.7037C223.222 13.4717 225.692 10.5457 228.732 10.5457C229.568 10.5457 230.48 10.6977 231.202 11.0017Z"
			fill={fill}
		/>
		<path
			d="M247.155 7.8477L241.531 22.5157L235.907 7.8477H232.411L239.897 26.7337L237.123 33.9157H240.429L250.461 7.8477H247.155Z"
			fill={fill}
		/>
		<path
			d="M262.894 26.9997H266.352V14.7257H277.448V11.6097H266.352V3.4777H279.918V0.399701H262.894V26.9997Z"
			fill={fill}
		/>
		<path
			d="M298.943 7.8477H295.713V16.2457C295.713 21.2997 293.433 24.3777 289.861 24.3777C287.163 24.3777 285.529 22.5157 285.529 19.9317V7.8477H282.299V20.5397C282.299 24.6057 285.149 27.3037 289.139 27.3037C292.065 27.3037 294.383 25.6697 295.713 23.3897V26.9997H298.943V7.8477Z"
			fill={fill}
		/>
		<path
			d="M315.403 11.0017L315.593 7.8857C314.947 7.6577 314.187 7.5437 313.389 7.5437C311.033 7.5437 308.525 9.2917 307.423 11.7237V7.8477H304.193V26.9997H307.423V19.7037C307.423 13.4717 309.893 10.5457 312.933 10.5457C313.769 10.5457 314.681 10.6977 315.403 11.0017Z"
			fill={fill}
		/>
		<path
			d="M318.74 26.9997H321.97V18.6017C321.97 13.5477 324.25 10.4697 327.822 10.4697C330.52 10.4697 332.154 12.3317 332.154 14.9157V26.9997H335.384V14.3077C335.384 10.2417 332.534 7.5437 328.544 7.5437C325.618 7.5437 323.3 9.1777 321.97 11.4577V7.8477H318.74V26.9997Z"
			fill={fill}
		/>
		<path
			d="M340.407 0.0957031V4.1997H344.131V0.0957031H340.407ZM340.635 26.9997H343.865V7.8477H340.635V26.9997Z"
			fill={fill}
		/>
		<path
			d="M359.28 26.8477L359.052 23.8837C358.292 24.2637 357.418 24.3777 356.658 24.3777C354.986 24.3777 353.846 23.3897 353.846 21.3757V10.6977H358.976V7.8477H353.846V2.0337H350.616V7.8477H347.044V10.6977H350.616V21.2617C350.616 25.4797 353.01 27.3037 356.278 27.3037C357.304 27.3037 358.33 27.1897 359.28 26.8477Z"
			fill={fill}
		/>
		<path
			d="M379.507 7.8477H376.277V16.2457C376.277 21.2997 373.997 24.3777 370.425 24.3777C367.727 24.3777 366.093 22.5157 366.093 19.9317V7.8477H362.863V20.5397C362.863 24.6057 365.713 27.3037 369.703 27.3037C372.629 27.3037 374.947 25.6697 376.277 23.3897V26.9997H379.507V7.8477Z"
			fill={fill}
		/>
		<path
			d="M395.968 11.0017L396.158 7.8857C395.512 7.6577 394.752 7.5437 393.954 7.5437C391.598 7.5437 389.09 9.2917 387.988 11.7237V7.8477H384.758V26.9997H387.988V19.7037C387.988 13.4717 390.458 10.5457 393.498 10.5457C394.334 10.5457 395.246 10.6977 395.968 11.0017Z"
			fill={fill}
		/>
		<path
			d="M415.742 16.5497C415.742 11.6097 412.246 7.5437 406.584 7.5437C400.808 7.5437 397.312 11.9897 397.312 17.4237C397.312 22.8577 400.922 27.3037 406.66 27.3037C411.068 27.3037 414.26 25.1377 415.21 21.4137L412.17 20.6917C411.6 23.0857 409.586 24.4537 406.66 24.4537C403.202 24.4537 400.77 21.9077 400.542 18.0317H415.666C415.704 17.6897 415.742 17.0057 415.742 16.5497ZM400.732 15.3337C401.378 12.1417 403.582 10.2417 406.584 10.2417C409.966 10.2417 412.018 12.4077 412.322 15.3337H400.732Z"
			fill={fill}
		/>
	</Icon>
);
