import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Styles
import { secondaryDark, secondaryLight, tertiary } from "../utils/colors";

// Components
import { SearchProducts } from "./search-products";

const Nav = styled.nav`
	padding: 0 15px;

	z-index: 10;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;

	color: ${(props) => props.textColor};

	& .logo {
		letter-spacing: 0.1em;
		color: ${(props) => props.textColor};

		margin: 17px 0;

		& a:hover,
		& a:focus {
			color: ${(props) => props.textColor};
		}
	}

	& ol {
		margin: 0;
		padding: 0;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		&:first-of-type {
			flex: 1;
			display: flex;
			justify-content: flex-start;
			margin-right: auto;

			& li:first-of-type {
				padding-left: 0;
			}
		}

		&:nth-of-type(2) {
			flex: 2;
			display: flex;
			justify-content: center;
			margin: 0 auto;

			@media (max-width: 1140px) {
				flex: unset;
			}
		}

		&:last-of-type {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			margin-left: auto;

			@media (max-width: 1140px) {
				flex: unset;
			}

			& li:last-of-type {
				padding-right: 0;
				margin-right: 0;
			}
		}

		& li {
			font-size: 16px;
			line-height: 1;

			margin: 17px;

			@media (max-width: 1420px) {
				margin: 17px 12px;
			}

			@media (max-width: 1380px) {
				margin: 17px 7px;
			}

			& a {
				z-index: 1;
				transition: 150ms color ease;

				&:hover {
					color: ${secondaryLight};
				}
			}

			& a.current {
				color: ${secondaryLight};
				&:hover {
					color: inherit;
				}
			}
		}
	}
`;

const MenuLink = styled.li`
	&.link-has-submenu {
		& ul {
			position: absolute;

			top: 50px;
			left: 0;
			right: 0;

			display: ${(props) => props.showSubMenu};
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			width: 100%;
			background-color: ${tertiary};

			margin: 0;
			padding: 0 15px;

			z-index: 0;

			& li {
				width: auto;
				color: ${secondaryDark};

				margin: 17px;

				@media (max-width: 1420px) {
					margin: 17px 12px;
				}

				@media (max-width: 1380px) {
					margin: 17px 7px;
				}
			}
		}
	}
`;

export const DesktopMenu = ({
	menu,
	quantity,
	textColor,
	location,
	activeSubMenu,
	setActiveSubMenu,
}) => {
	const menuItems = menu.map((menuItem, index) => {
		if (menuItem.slice_type === "section") {
			const subMenu = menuItem.items
				.filter((item) => item.submenu_link.document !== null)
				.map((subMenuItem, index) => (
					<li
						key={`sub_navigation_item_${index}_${subMenuItem.submenu_link.document.id}`}
					>
						<Link
							to={subMenuItem.submenu_link.document.url}
							aria-label={subMenuItem.submenu_link.document.data.title.text}
							partiallyActive
							activeClassName="current"
						>
							{subMenuItem.submenu_link.document.data.title.text}
						</Link>
					</li>
				));

			const doesLinkHaveSubMenu = subMenu.length >= 1;

			return (
				<MenuLink
					key={`navigation_item_${index}`}
					className={doesLinkHaveSubMenu ? `link-has-submenu` : ``}
					showSubMenu={activeSubMenu === menuItem.id ? `flex` : `none`}
					onMouseOver={() => {
						if (doesLinkHaveSubMenu) {
							setActiveSubMenu(menuItem.id);
						} else {
							setActiveSubMenu(null);
						}
					}}
				>
					<button>{menuItem.primary.link_text}</button>

					{subMenu.length >= 1 && <ul className="link-submenu">{subMenu}</ul>}
				</MenuLink>
			);
		}

		if (menuItem.slice_type === "link_with_alt_title") {
			return (
				<MenuLink
					key={`navigation_item_${index}`}
					onMouseOver={() => setActiveSubMenu(null)}
				>
					<Link
						to={menuItem.primary.link.url}
						aria-label={menuItem.primary.link_text}
						partiallyActive
						activeClassName="current"
					>
						{menuItem.primary.link_text}
					</Link>
				</MenuLink>
			);
		}

		if (menuItem.slice_type === "link") {
			return (
				<MenuLink
					key={`navigation_item_${index}`}
					onMouseOver={() => setActiveSubMenu(null)}
				>
					<Link
						to={menuItem.primary.link.document.url}
						aria-label={menuItem.primary.link.document.data.title.text}
						partiallyActive
						activeClassName="current"
					>
						{menuItem.primary.link.document.data.title.text}
					</Link>
				</MenuLink>
			);
		}
	});

	return (
		<Nav role="navigation" aria-label="main navigation" textColor={textColor}>
			<ol>
				<li className="logo">
					<Link aria-label="home" to={`/`}>
						CHRISTIAN WATSON
					</Link>
				</li>
			</ol>

			<ol>{menuItems}</ol>

			<ol>
				<li>
					<SearchProducts location={location} fillColor={textColor} />
				</li>
				<li>
					<Link
						aria-label="account"
						to="/account"
						partiallyActive
						activeClassName="current"
					>
						Account
					</Link>
				</li>
				<li>
					<Link
						aria-label="basket"
						to="/basket"
						partiallyActive
						activeClassName="current"
					>
						Basket {quantity >= 1 && `( ${quantity} )`}
					</Link>
				</li>
			</ol>
		</Nav>
	);
};
