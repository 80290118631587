import React from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const Icon = styled.svg`
	cursor: pointer;

	& path {
		transition: 250ms stroke ease;
		stroke: ${(props) => (props.isOpen ? secondaryLight : props.fillColor)};
	}

	&:hover {
		& path {
			stroke: ${(props) => (props.isOpen ? props.fillColor : secondaryLight)};
		}
	}
`;

export const SearchIcon = ({ isOpen, fillColor }) => (
	<Icon
		width="13"
		height="13"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		isOpen={isOpen}
		fillColor={fillColor}
	>
		<path d="M9 9.47852L12 12.5279" strokeWidth="1.2" />
		<path
			d="M10.4 5.92067C10.4 8.68617 8.19699 10.9113 5.5 10.9113C2.80301 10.9113 0.6 8.68617 0.6 5.92067C0.6 3.15516 2.80301 0.930078 5.5 0.930078C8.19699 0.930078 10.4 3.15516 10.4 5.92067Z"
			strokeWidth="1.2"
		/>
	</Icon>
);
