import React from "react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

export const Cookies = ({ location }) => (
	<CookieConsent
		location="bottom"
		buttonText="Accept and Close"
		cookieName="cw-gdpr-google-analytics"
		containerClasses="cookie-consent-container"
		buttonClasses="cookie-button"
		disableStyles={true}
		onAccept={() => {
			initializeAndTrack(location);
		}}
		expires={150}
	>
		This website uses cookies to enhance the user experience. Learn more about
		how we use cookies by reviewing our{" "}
		<Link to={"/privacy-policy"}>privacy policy</Link>.
	</CookieConsent>
);
