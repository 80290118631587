import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "react-modal";

// Components
import { PopupForm } from "../mailchimp/popup-form";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Icons
import { CloseIcon } from "../icons/close-icon";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const PopupContainer = styled.div`
	max-width: 500px;

	& .image-container {
		position: relative;

		& img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		& .close-popup {
			position: absolute;
			top: 10px;
			right: 10px;

			width: 20px;
			height: 20px;

			& svg {
				& path {
					stroke: ${secondaryDark};
					transition: 250ms stroke ease;
				}

				&:hover {
					& path {
						stroke: ${secondaryLight};
					}
				}
			}
		}
	}

	@media (max-width: 600px) {
		max-width: 80%;
	}

	@media (max-height: 800px) {
		/* max-width: 80%; */

		& .aspect-ratio-container {
			&::before {
				padding-top: 50%;
			}
		}
	}
`;

export const Popup = ({ isPopupOpen, setIsPopupOpen }) => {
	const data = useStaticQuery(graphql`
		{
			prismicPopup {
				data {
					is_popup_active
					text {
						html
					}
					privacy_policy {
						html
					}
					call_to_action_text
					image {
						alt
						fluid {
							srcSet
							src
						}
					}
				}
			}
		}
	`);

	if (data.prismicPopup.data.is_popup_active) {
		return (
			<Modal
				isOpen={isPopupOpen}
				portalClassName={`ReactModalPortal-mailchimp`}
				contentLabel={`Mailchimp Signup Form`}
				shouldFocusAfterRender={true}
			>
				<PopupContainer>
					<div className="image-container">
						<AspectRatioImageContainer image={null} padding={66}>
							<img
								src={data.prismicPopup.data.image.fluid.src}
								srcSet={data.prismicPopup.data.image.fluid.srcSet}
								alt={data.prismicPopup.data.image.alt}
								loading="eager"
							/>
						</AspectRatioImageContainer>

						<button
							className="close-popup"
							onClick={() => setIsPopupOpen(false)}
						>
							<CloseIcon />
						</button>
					</div>
					<div className="email-container">
						<PopupForm data={data.prismicPopup.data} />
					</div>
				</PopupContainer>
			</Modal>
		);
	} else {
		return null;
	}
};
