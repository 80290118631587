import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";

// Styles
import { secondaryLight, tertiary } from "../utils/colors";

// Icons
import { SearchIcon } from "../icons/search-icon";
import { CloseIcon } from "../icons/close-icon";

const SearchContainer = styled.div`
	width: 100%;

	margin: 0;
	padding: 0;

	@media (max-width: 960px) {
		width: 100%;
		max-width: 100%;

		margin: 0;
		padding: ${(props) => props.padding};

		& button {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;

			width: 100%;
			line-height: 50px;
		}
	}
`;

const SearchForm = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	max-width: 300px;
	width: 100%;

	border-bottom: 1px solid ${secondaryLight};

	& input {
		padding: 0 10px 0 0;
		margin: 0;

		border: 0;

		width: calc(100% - 48px);
		background-color: transparent;
	}

	& button[type="submit"] {
		margin: 0 10px 0 0;
	}

	& .search-trigger {
		margin: 0 0 3px 0;
		display: block;

		font-size: 20px;
	}

	& button {
		margin: 0;
		cursor: pointer;
		width: auto;

		&:hover {
			stroke: ${secondaryLight};
		}
	}
`;

const SearchWrapper = styled.div`
	position: absolute;

	top: 50px;
	left: 0;
	right: 0;

	display: ${(props) => props.isOpen};
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	width: 100%;
	background-color: ${tertiary};

	margin: 0;
	padding: 17px 15px;

	z-index: 0;
`;

export const SearchProducts = ({ location, isMobileSearch, fillColor }) => {
	const data = useStaticQuery(graphql`
		{
			localSearchProducts {
				index
				store
			}
		}
	`);

	const [isSearchOpen, setSearchOpen] = useState(false);
	const [query, setQuery] = useState("");
	const results = useFlexSearch(
		query,
		data.localSearchProducts.index,
		JSON.parse(data.localSearchProducts.store)
	);

	const onEscape = (event) => {
		if (event.keyCode === 27 && isSearchOpen === true) {
			setSearchOpen(false);
		}
	};

	const navigateToSearchResultsPage = (event) => {
		event.preventDefault();
		setSearchOpen(false);
		navigate(`/search/?q=${query}`, {
			state: {
				searchResults: results,
				searchQuery: query,
			},
		});
	};

	useEffect(() => {
		if (typeof window !== undefined && isSearchOpen === true) {
			document.getElementById("search-input").focus();
			document.addEventListener("keydown", (event) => onEscape(event));
		}
	}, [isSearchOpen]);

	useEffect(() => {
		setSearchOpen(false);
		setQuery("");
	}, [location]);

	return (
		<SearchContainer
			isFilterActive={isSearchOpen}
			padding={isSearchOpen ? `15px 0 16px 0` : `0`}
		>
			{isSearchOpen && (
				<SearchWrapper isOpen={isSearchOpen === true ? `flex` : `none`}>
					<SearchForm onSubmit={navigateToSearchResultsPage}>
						<input
							id="search-input"
							name="query"
							type="text"
							value={query}
							onChange={(e) => setQuery(e.target.value)}
						/>
						{isSearchOpen === true && (
							<button type="submit">
								<span className="search-trigger">→</span>
							</button>
						)}
						{isSearchOpen === true && (
							<button onClick={() => setSearchOpen(false)}>
								<CloseIcon isOpen={isSearchOpen} />
							</button>
						)}
					</SearchForm>
				</SearchWrapper>
			)}

			<button onClick={() => setSearchOpen(!isSearchOpen)}>
				{isMobileSearch === true && <span>Search</span>}
				<SearchIcon isOpen={isSearchOpen} fillColor={fillColor} />
			</button>
		</SearchContainer>
	);
};
