import React from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const Icon = styled.svg`
	cursor: pointer;

	& path {
		transition: 250ms stroke ease;
		stroke: ${secondaryLight};
	}

	&:hover {
		& path {
			stroke: ${secondaryDark};
		}
	}
`;

export const CloseIcon = ({ isOpen }) => (
	<Icon
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		isOpen={isOpen}
	>
		<path d="M1 1L11 11" strokeWidth="1.2" />
		<path d="M11 1L1 11" strokeWidth="1.2" />
	</Icon>
);
