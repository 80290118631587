import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
	// width: 225px;
	// height: auto;

	// @media (max-width: 768px) {
	// 	width: 210px;
	// 	height: auto;
	// }

	// @media (max-width: 350px) {
	// 	width: 195px;
	// 	height: auto;
	// }
`;

export const WLogo = ({ logoColor }) => (
	<Icon
		width="70"
		height="40"
		viewBox="0 0 70 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.6">
			<path
				d="M39.9966 19.9045L39.9846 19.8953C38.3751 18.9301 36.7698 18.4507 34.9864 18.4507C33.4907 18.4507 32.0489 18.7994 30.7077 19.4958L11.4665 30.6177C9.96071 31.4504 8.47407 31.3722 7.65771 31.1603C7.65771 31.1603 7.73321 31.2438 7.80362 31.2911C9.37593 32.5054 11.716 32.9141 14.1661 31.4416L31.0487 21.7445C32.3351 21.0562 33.5541 20.7289 34.9677 20.7149C37.2506 20.6787 38.8692 21.6962 38.8692 21.6962L55.8366 31.4416C58.2814 32.9141 60.6146 32.5054 62.1938 31.2911C62.2391 31.2438 62.359 31.1603 62.359 31.1603C61.5295 31.3722 60.0429 31.4506 58.5312 30.6177L39.9966 19.9045Z"
				fill="#383E42"
			/>
			<path
				d="M39.3778 24.3029V24.306C39.3537 24.285 39.2961 24.2497 39.2559 24.2256L39.2286 24.2105C37.8277 23.4163 36.5777 22.9392 34.9863 22.9392C33.3533 22.9427 32.159 23.4486 30.6955 24.2533L30.6165 24.3031L15.5832 32.9915C14.0815 33.8259 12.5908 33.7525 11.7654 33.5365C11.7654 33.5365 11.863 33.6235 11.9295 33.668C13.4956 34.8822 15.8399 35.2971 18.2803 33.82L32.2804 25.763C33.1032 25.3699 33.9809 25.155 34.9532 25.155C36.0471 25.155 37.0254 25.3801 37.9857 25.9135L51.717 33.82C54.161 35.2971 56.5011 34.8823 58.0683 33.668C58.1318 33.6235 58.2354 33.5365 58.2354 33.5365C57.4041 33.7524 55.9133 33.8259 54.4054 32.9915L39.3778 24.3029Z"
				fill="#383E42"
			/>
			<path
				d="M66.4578 28.7967C66.4578 28.7967 66.5825 28.76 66.6549 28.7283C68.4899 27.9669 70.0501 26.1501 69.9987 23.2991V0C68.8371 0 67.8989 0.936821 67.886 2.08454L67.8829 2.08717V25.218C67.8507 26.94 67.0575 28.1978 66.4578 28.7967Z"
				fill="#383E42"
			/>
			<path
				d="M49.8757 38.3472C49.9312 38.2968 50.041 38.2163 50.041 38.2163C49.2055 38.4281 47.7249 38.5021 46.2191 37.6677L37.8325 32.8203C36.9921 32.2521 36.0097 31.8257 34.9867 31.8257C34.0295 31.8257 33.097 32.2344 32.2892 32.7497L23.7778 37.6677C22.2725 38.5021 20.7849 38.4281 19.9475 38.2163C19.9475 38.2163 20.0603 38.2968 20.1185 38.3472C21.6994 39.562 24.034 39.9733 26.4749 38.4991L33.341 34.547C33.8162 34.2587 34.4136 34.0473 34.9867 34.0473C35.5869 34.0473 36.1083 34.2209 36.6811 34.5536L43.5194 38.4991C45.9604 39.9733 48.3034 39.562 49.8757 38.3472Z"
				fill="#383E42"
			/>
			<path
				d="M37.757 28.0881C36.8238 27.6568 36.217 27.3699 34.9928 27.3699C34.0603 27.3699 33.2429 27.6024 32.2892 28.0881L19.6666 35.3429C18.1643 36.1792 16.6711 36.1049 15.8489 35.8894C15.8489 35.8894 15.9459 35.9739 16.0068 36.02C17.5728 37.235 19.9167 37.6462 22.358 36.1733L32.6278 30.2562C33.4688 29.7585 34.3037 29.6015 34.9982 29.6015C35.8394 29.6015 36.6195 29.824 37.3331 30.2225L47.4377 36.0553C49.8889 37.5305 52.229 37.1162 53.8011 35.9083C53.8625 35.8541 53.959 35.7704 53.959 35.7704C53.1307 35.9857 51.6372 36.0645 50.1323 35.225L37.757 28.0881Z"
				fill="#383E42"
			/>
			<path
				d="M3.33854 28.7266C3.40895 28.757 3.53681 28.7952 3.53681 28.7952C2.93627 28.1963 2.14421 26.9377 2.11647 25.2158V2.08601V2.08272C2.09842 0.933854 1.15963 0.00146484 0.0017321 0.00146484V23.2966C-0.0594863 26.1481 1.51069 27.9646 3.33854 28.7266Z"
				fill="#383E42"
			/>
			<path
				d="M65.9121 28.8968C65.9241 28.8968 65.9545 28.8968 65.9604 28.8909C65.9543 28.8968 65.9241 28.8968 65.9121 28.8968Z"
				fill="#383E42"
			/>
			<path
				d="M59.6887 26.5521L61.7944 27.7635V4.74365C60.6188 4.74365 59.6736 5.69131 59.6736 6.86118C59.6736 6.86725 59.6797 6.87661 59.6797 6.88285L59.6887 26.5521Z"
				fill="#383E42"
			/>
			<path
				d="M66.0581 28.8818C66.073 28.8788 66.0954 28.8759 66.1133 28.8696C66.0954 28.8759 66.073 28.879 66.0581 28.8818Z"
				fill="#383E42"
			/>
			<path
				d="M55.5841 24.1782L57.6898 25.3955L57.693 7.1272H57.6898C56.5162 7.13097 55.5688 8.07255 55.5688 9.24555V9.26573L55.5841 24.1782Z"
				fill="#383E42"
			/>
			<path
				d="M66.3318 28.8273C66.3753 28.8176 66.4175 28.8061 66.4578 28.7891C66.4176 28.8061 66.3753 28.8178 66.3318 28.8273Z"
				fill="#383E42"
			/>
			<path
				d="M63.6626 28.6898C63.9797 28.786 64.498 28.9048 65.0708 28.9306C65.5891 28.0683 65.9242 26.9803 65.896 25.6759V2.38599C64.7294 2.38599 63.7762 3.332 63.7762 4.50549C63.7762 4.51779 63.7793 4.53289 63.7793 4.54192V27.5851C63.7722 27.9787 63.7181 28.3501 63.6626 28.693V28.6898Z"
				fill="#383E42"
			/>
			<path
				d="M66.1951 28.8577C66.2253 28.8546 66.2533 28.8487 66.2716 28.8457C66.2535 28.8487 66.2253 28.8546 66.1951 28.8577Z"
				fill="#383E42"
			/>
			<path
				d="M6.33145 28.6931C6.27318 28.3503 6.22772 27.978 6.22477 27.5884V4.54373V4.50549C6.22477 3.332 5.26447 2.38599 4.09443 2.38599V25.6759C4.07342 26.9803 4.41152 28.0655 4.92392 28.9306C5.49343 28.9048 6.01124 28.7872 6.33145 28.6931Z"
				fill="#383E42"
			/>
			<path
				d="M51.4731 21.8015L53.5879 23.0188V23.0247L53.591 9.50073H53.5879C52.4244 9.50073 51.4731 10.4484 51.4731 11.6188V11.6476V21.8015Z"
				fill="#383E42"
			/>
			<path
				d="M65.3174 28.9321H65.3141H65.2225C65.1713 28.9321 65.1259 28.929 65.0737 28.929H65.0706C64.4978 28.9061 63.9734 28.786 63.6624 28.6883V28.6915C63.3272 28.5899 62.9768 28.4418 62.6328 28.2455L61.7943 27.7619L59.6886 26.5506L57.6897 25.3919L55.584 24.1795L53.5879 23.0215V23.0184L51.4732 21.801L40.8983 15.685L40.6971 15.5689C38.7342 14.4614 37.0283 14.0317 34.9922 14.0317C32.9659 14.0317 31.1159 14.5348 29.299 15.5689L19.2431 21.3837L8.09514 27.8272L7.37644 28.2454C7.0111 28.4492 6.66579 28.5897 6.33426 28.6913C5.9804 28.8009 5.41976 28.9288 4.76753 28.9319H4.67644C4.23708 28.9288 3.84416 28.8787 3.54004 28.789C3.54004 28.789 3.64016 28.882 3.70154 28.9229C5.27073 30.1436 7.62312 30.5544 10.0548 29.0754L30.2864 17.4706C31.8471 16.5868 33.5873 16.2473 34.992 16.2473C37.7687 16.2473 39.8987 17.5665 39.8987 17.5665L59.9381 29.0754C62.3889 30.5546 64.729 30.1438 66.2953 28.9229C66.3537 28.882 66.4545 28.789 66.4545 28.789C66.1558 28.8789 65.7661 28.929 65.3174 28.9321Z"
				fill="#383E42"
			/>
			<path
				d="M10.3174 6.86128C10.3174 5.69091 9.36971 4.74146 8.2146 4.74146V27.7633L10.3057 26.5524L10.3174 6.88229V6.86128Z"
				fill="#383E42"
			/>
			<path
				d="M18.5213 11.6472V11.62C18.5213 10.4496 17.5731 9.50049 16.4097 9.50049V23.0251L18.5213 21.8013V11.6472Z"
				fill="#383E42"
			/>
			<path
				d="M14.4257 9.24746C14.4257 8.07381 13.4775 7.12763 12.3068 7.12451H12.3018L12.3068 25.393L14.4104 24.1801L14.4221 9.26567C14.4221 9.26288 14.4257 9.25041 14.4257 9.24746Z"
				fill="#383E42"
			/>
		</g>
	</Icon>
);
