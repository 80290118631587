import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
	cursor: pointer;

	margin: 0 auto;
	padding: 0 20px;

	display: block;

	width: calc(100% - 40px);
	height: auto;
	max-width: 860px;

	& path {
		transition: 500ms color ease-in-out;
		fill: ${(props) => props.fill};
	}
`;

export const SplashIcon = ({ fill }) => (
	<Icon
		width="858"
		height="58"
		viewBox="0 0 858 58"
		fill={fill}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27.4798 57.6404C39.5598 57.6404 48.2798 50.9204 51.0798 40.3604L43.7998 38.8404C41.7998 46.6004 36.1198 51.0804 27.3998 51.0804C16.0398 51.0804 7.79979 43.3204 7.79979 29.0004C7.79979 14.6803 16.0398 6.84035 27.3998 6.84035C36.1198 6.84035 42.1198 11.5603 43.8798 19.6404L51.0798 18.0403C48.7598 7.24035 39.7198 0.360352 27.4798 0.360352C13.4798 0.360352 0.279785 9.72035 0.279785 29.0004C0.279785 48.2804 13.3998 57.6404 27.4798 57.6404Z"
			fill={fill}
		/>
		<path
			d="M104.269 1.00035V24.2003H75.2292V1.00035H67.9492V57.0004H75.2292V30.7603H104.269V57.0004H111.549V1.00035H104.269Z"
			fill={fill}
		/>
		<path
			d="M174.782 57.0004L158.622 30.9203C165.102 28.6803 169.022 23.4003 169.022 16.5203C169.022 7.16035 162.062 1.00035 151.422 1.00035H131.262V57.0004H138.542V32.0404H150.942L166.462 57.0004H174.782ZM138.542 7.48035H150.622C157.502 7.48035 161.582 10.8403 161.582 16.5203C161.582 22.2003 157.502 25.4804 150.622 25.4804H138.542V7.48035Z"
			fill={fill}
		/>
		<path d="M189.73 57.0004H197.01V1.00035H189.73V57.0004Z" fill={fill} />
		<path
			d="M234.397 57.6404C246.637 57.6404 254.717 51.4003 254.717 41.8803C254.717 19.4803 222.717 27.8003 222.717 15.0003C222.717 9.96035 227.037 6.84035 234.237 6.84035C241.277 6.84035 246.077 10.6803 247.197 16.7603L253.837 14.2003C252.397 5.88035 244.477 0.360352 234.237 0.360352C223.197 0.360352 215.357 6.28035 215.357 15.0003C215.357 34.8403 247.197 27.0003 247.197 41.9604C247.197 47.5603 242.237 51.1603 234.557 51.1603C227.117 51.1603 221.117 46.0403 219.997 38.2003L213.117 40.4404C214.957 50.6804 223.597 57.6404 234.397 57.6404Z"
			fill={fill}
		/>
		<path
			d="M283.467 57.0004H290.747V7.48035H308.507V1.00035H265.627V7.48035H283.467V57.0004Z"
			fill={fill}
		/>
		<path d="M323.574 57.0004H330.854V1.00035H323.574V57.0004Z" fill={fill} />
		<path
			d="M388.8 57.0004H396.4L374.16 1.00035H367.52L345.36 57.0004H352.88L358.4 42.7604H383.28L388.8 57.0004ZM360.88 36.2803L370.88 10.6003L380.8 36.2803H360.88Z"
			fill={fill}
		/>
		<path
			d="M410.824 57.0004H417.944V12.6003L446.184 57.0004H454.264V1.00035H447.144V45.5603L418.904 1.00035H410.824V57.0004Z"
			fill={fill}
		/>
		<path
			d="M513.917 57.0004H521.517L535.117 11.8803L548.557 57.0004H556.237L573.197 1.00035H565.677L552.317 48.6003L538.877 1.00035H531.277L517.757 48.6003L504.397 1.00035H496.877L513.917 57.0004Z"
			fill={fill}
		/>
		<path
			d="M621.191 57.0004H628.791L606.551 1.00035H599.911L577.751 57.0004H585.271L590.791 42.7604H615.671L621.191 57.0004ZM593.271 36.2803L603.271 10.6003L613.191 36.2803H593.271Z"
			fill={fill}
		/>
		<path
			d="M649.233 57.0004H656.513V7.48035H674.273V1.00035H631.393V7.48035H649.233V57.0004Z"
			fill={fill}
		/>
		<path
			d="M706.787 57.6404C719.027 57.6404 727.107 51.4003 727.107 41.8803C727.107 19.4803 695.107 27.8003 695.107 15.0003C695.107 9.96035 699.427 6.84035 706.627 6.84035C713.667 6.84035 718.467 10.6803 719.587 16.7603L726.227 14.2003C724.787 5.88035 716.867 0.360352 706.627 0.360352C695.587 0.360352 687.747 6.28035 687.747 15.0003C687.747 34.8403 719.587 27.0003 719.587 41.9604C719.587 47.5603 714.627 51.1603 706.947 51.1603C699.507 51.1603 693.507 46.0403 692.387 38.2003L685.507 40.4404C687.347 50.6804 695.987 57.6404 706.787 57.6404Z"
			fill={fill}
		/>
		<path
			d="M768.942 57.6404C784.862 57.6404 796.462 45.6404 796.462 29.0004C796.462 12.3604 784.862 0.360352 768.942 0.360352C752.942 0.360352 741.342 12.3604 741.342 29.0004C741.342 45.6404 752.942 57.6404 768.942 57.6404ZM768.862 51.0804C757.262 51.0804 748.782 41.8004 748.782 29.0004C748.782 16.2003 757.262 6.84035 768.862 6.84035C780.622 6.84035 789.022 16.2003 789.022 29.0004C789.022 41.8004 780.622 51.0804 768.862 51.0804Z"
			fill={fill}
		/>
		<path
			d="M813.621 57.0004H820.741V12.6003L848.981 57.0004H857.061V1.00035H849.941V45.5603L821.701 1.00035H813.621V57.0004Z"
			fill={fill}
		/>
	</Icon>
);
