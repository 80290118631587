import React, { createContext } from "react";

const MenuColor = createContext({
	menuColor: "#ffffff",
	setMenuColor: () => {}
});

export class MenuColorProvider extends React.Component {
	setMenuColor = newMenuColor => {
		this.setState({ menuColor: newMenuColor });
	};

	state = {
		menuColor: "#ffffff",
		setMenuColor: this.setMenuColor
	};

	render() {
		return (
			<MenuColor.Provider value={this.state}>
				{this.props.children}
			</MenuColor.Provider>
		);
	}
}

export const MenuColorConsumer = MenuColor.Consumer;
