import React, { Component } from "react";
import StoreContext, { defaultStoreContext } from "../context/store";
import { navigate } from "@reach/router";
import { Normalize } from "styled-normalize";
import { Location } from "@reach/router";

import { DefaultSEO } from "./default-seo";

// Adding in array.flat() polyfill
import "array-flat-polyfill";

// Context
import { MenuColorProvider, MenuColorConsumer } from "../context/menu-color";

// Components
import GlobalStyles from "../styles/globalStyles";
import { App } from "./app";

const isBrowser = typeof window !== "undefined";

class Layout extends Component {
	getlocalStorage(value) {
		try {
			return JSON.parse(localStorage.getItem(value));
		} catch (e) {
			return "";
		}
	}

	state = {
		store: {
			...defaultStoreContext,
			customerAccessToken: this.getlocalStorage("customerAccessToken"),
			addVariantToCart: (variantId, quantity) => {
				this.setState((state) => ({
					store: {
						...state.store,
						adding: true,
					},
				}));

				const { checkout, client } = this.state.store;
				const checkoutId = checkout.id;
				const lineItemsToUpdate = [
					{ variantId, quantity: parseInt(quantity, 10) },
				];

				return client.checkout
					.addLineItems(checkoutId, lineItemsToUpdate)
					.then((checkout) => {
						this.setState((state) => ({
							store: {
								...state.store,
								checkout,
								adding: false,
							},
						}));
					})
					.then((result) => {
						localStorage.setItem(
							"cw-checkout",
							JSON.stringify(this.state.store.checkout)
						);
					});
			},

			addVariantToCartAndBuyNow: (variantId, quantity) => {
				this.setState((state) => ({
					store: {
						...state.store,
						adding: true,
					},
				}));

				const { checkout, client } = this.state.store;
				const checkoutId = checkout.id;
				const lineItemsToUpdate = [
					{ variantId, quantity: parseInt(quantity, 10) },
				];
				return client.checkout
					.addLineItems(checkoutId, lineItemsToUpdate)
					.then((checkout) => {
						this.setState((state) => ({
							store: {
								...state.store,
								checkout,
								adding: false,
							},
						}));
						navigate(checkout.webUrl);
					});
			},

			removeLineItem: (client, checkoutID, lineItemID) => {
				return client.checkout
					.removeLineItems(checkoutID, [lineItemID])
					.then((result) => {
						this.setState((state) => ({
							store: {
								...state.store,
								checkout: result,
							},
						}));
					})
					.then((result) => {
						localStorage.setItem(
							"cw-checkout",
							JSON.stringify(this.state.store.checkout)
						);
					});
			},

			updateLineItem: (client, checkoutID, lineItemID, quantity) => {
				const lineItemsToUpdate = [
					{
						id: lineItemID,
						quantity: parseInt(quantity, 10),
					},
				];
				return client.checkout
					.updateLineItems(checkoutID, lineItemsToUpdate)
					.then((result) => {
						this.setState((state) => ({
							store: {
								...state.store,
								checkout: result,
								adding: true,
							},
						}));
					})
					.then((result) => {
						localStorage.setItem(
							"cw-checkout",
							JSON.stringify(this.state.store.checkout)
						);
						this.setState((state) => ({
							store: {
								...state.store,
								adding: false,
							},
						}));
					});
			},

			updateFilterType: (type) => {
				this.setState((state) => ({
					store: {
						...state.store,
						filteredType: type,
					},
				}));
			},

			updateFilterSort: (sort) => {
				this.setState((state) => ({
					store: {
						...state.store,
						filteredSort: sort,
					},
				}));
			},

			setValue: (value) => {
				isBrowser &&
					localStorage.setItem("customerAccessToken", JSON.stringify(value));
				this.setState((state) => ({
					store: {
						...state.store,
						customerAccessToken: value,
					},
				}));
			},
		},
	};

	async initializeCheckout() {
		// Check if card exits already
		const isBrowser = typeof window !== "undefined";
		const existingCheckoutID = isBrowser
			? localStorage.getItem("cw_shopify_checkout_id")
			: null;

		const setCheckoutInState = (checkout) => {
			if (isBrowser) {
				localStorage.setItem("cw_shopify_checkout_id", checkout.id);
			}

			this.setState((state) => ({
				store: {
					...state.store,
					checkout,
					adding: false,
				},
			}));
		};

		const createNewCheckout = () => this.state.store.client.checkout.create();
		const fetchCheckout = (id) => this.state.store.client.checkout.fetch(id);
		if (existingCheckoutID) {
			try {
				const checkout = await fetchCheckout(existingCheckoutID);

				// Make sure this cart hasn’t already been purchased.
				if (!checkout.completedAt) {
					setCheckoutInState(checkout);
					return;
				}
			} catch (e) {
				localStorage.setItem("cw_shopify_checkout_id", null);
			}
		}
		const newCheckout = await createNewCheckout();
		setCheckoutInState(newCheckout);
	}

	componentDidMount() {
		this.initializeCheckout();
	}

	render() {
		const { children } = this.props;

		return (
			<StoreContext.Provider value={this.state.store}>
				<MenuColorProvider>
					<MenuColorConsumer>
						{({ menuColor }) => (
							<>
								<Normalize />
								<GlobalStyles />
								<DefaultSEO />
								<Location>
									{({ location }) => (
										<App
											menuColor={menuColor}
											children={children}
											location={location.pathname}
										/>
									)}
								</Location>
							</>
						)}
					</MenuColorConsumer>
				</MenuColorProvider>
			</StoreContext.Provider>
		);
	}
}

export default Layout;
