import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GridThemeProvider } from "styled-bootstrap-grid";
import Div100vh from "react-div-100vh";
import smoothscroll from "smoothscroll-polyfill";
import { useSessionStorage } from "react-use";

// Context
import WindowWidthContext, { windowWidth } from "../context/window-width";
import { FooterColor } from "../context/footer-color";

// Components
import { Splash } from "../splash/splash";
import { Header } from "./header";
import { Footer } from "./footer";
import { Cookies } from "./cookies";
import { tertiary } from "../utils/colors";
import { Popup } from "../popup/popup";

const Container = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	position: relative;
`;

const Main = styled.main`
	flex: 1;
`;

const SplashWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	cursor: pointer;

	z-index: 1000;

	transform: translateY(${(props) => (props.disable ? "-100%" : "0")});
	transition-delay: 1500ms;
`;

const gridTheme = {
	gridColumns: 12, // default 12
	breakpoints: {
		// defaults below
		xxl: 1440,
		xl: 1200,
		lg: 992,
		md: 768,
		sm: 576,
		xs: 575,
	},
	row: {
		padding: 15, // default 15
	},
	col: {
		padding: 15, // default 15
	},
	container: {
		padding: 0, // default 15
		maxWidth: {
			// defaults below
			xxl: 1141,
			xl: 1140,
			lg: 960,
			md: 720,
			sm: 540,
			xs: 540,
		},
	},
};

export const App = ({ menuColor, children, location }) => {
	const [hide, hideSplash] = useState(false);
	const [slide, slideUp] = useState(false);
	const [disable, disableSplash] = useState(false);

	// Footer Color
	const [footerColor, setFooterColor] = useState(tertiary);

	// Popup
	const [isPopupOpen, setIsPopupOpen] = useSessionStorage("isPopupOpen", false);

	useEffect(() => {
		// kick off the polyfill!
		smoothscroll.polyfill();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			hideSplash(true);
			const slideUpTimer = setTimeout(() => {
				slideUp(true);
				disableSplash(true);
			}, 500);
			return () => clearTimeout(slideUpTimer);
		}, 750);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (isPopupOpen === false) {
			const popupTimer = setTimeout(() => {
				setIsPopupOpen(true);
			}, 10000);
			return () => clearTimeout(popupTimer);
		}
	}, []);

	function triggerDisableSplash() {
		disableSplash(true);
	}

	// Window Width
	const [updatedWindowWidth, setWindowWidth] = useState(1024);
	useEffect(() => {
		if (typeof window !== `undefined`) {
			let getWindowWidth = window.innerWidth;
			setWindowWidth(getWindowWidth);
		}
	}, []);

	const data = useStaticQuery(graphql`
		{
			prismicMenus {
				data {
					show_splash_page
				}
			}
		}
	`);

	return (
		<FooterColor.Provider value={[footerColor, setFooterColor]}>
			<GridThemeProvider gridTheme={gridTheme}>
				<WindowWidthContext.Provider value={updatedWindowWidth}>
					{data.prismicMenus.data.show_splash_page && (
						<>
							{location === "/" && (
								<SplashWrapper
									disable={disable}
									onClick={() => {
										hideSplash(true);
										slideUp(true);
										triggerDisableSplash();
									}}
								>
									<Div100vh
										style={{
											position: "absolute",
											height: "100rvh",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
										}}
									>
										<Splash hide={hide} slide={slide} disable={disable} />
									</Div100vh>
								</SplashWrapper>
							)}
						</>
					)}

					<Container>
						<Header
							menuColor={menuColor}
							windowWidth={windowWidth}
							location={location}
						/>
						<Main>{children}</Main>
						<Cookies location={location} />
						<Footer location={location} footerColor={footerColor} />

						{isPopupOpen && (
							<Popup
								isPopupOpen={isPopupOpen}
								setIsPopupOpen={setIsPopupOpen}
							/>
						)}
					</Container>
				</WindowWidthContext.Provider>
			</GridThemeProvider>{" "}
		</FooterColor.Provider>
	);
	// }
};
