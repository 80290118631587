import React, { useState, useEffect } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Styles
import { secondaryDark, tertiary, secondaryLight } from "../utils/colors";

const MailchimpFormContainer = styled.div`
	position: relative;
	width: 100%;
	background-color: ${secondaryLight};

	min-height: 266px;

	padding: 30px;

	@media (max-width: 768px) {
		padding: 30px 15px;
	}

	@media (max-width: 600px) {
		& .mailchimp-signup-form {
			margin: 0;
		}
	}

	& .title,
	& .text {
		margin: 0 0 25px 0;
	}

	& .text {
		& p {
			margin: 0;
		}
	}

	& input {
		::-webkit-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		::-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		:-ms-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		:-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		::placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
	}

	& .gdpr-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;

		& p {
			margin: 0;
		}

		& .gdpr-checkbox {
			width: 16px;
			height: 16px;

			position: relative;

			margin: 0 10px 0 0;
		}
		& .gdpr-checkbox label {
			width: 14px;
			height: 14px;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			border: 1px solid ${secondaryDark};
		}
		& .gdpr-checkbox label:after {
			content: "";
			width: 9px;
			height: 5px;
			position: absolute;
			top: 2px;
			left: 2px;
			border: 1px solid ${secondaryDark};
			border-top: none;
			border-right: none;
			background: transparent;
			opacity: 0;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
		& .gdpr-checkbox label:hover::after {
			opacity: 1;
		}
		& .gdpr-checkbox input[type="checkbox"] {
			width: 16px;
			height: 16px;

			visibility: hidden;
		}
		& .gdpr-checkbox input[type="checkbox"]:checked + label:after {
			opacity: 1;
		}

		@media (max-width: 600px) {
			margin: 0 0 25px 0;
		}
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	box-sizing: content-box;

	& .field {
		border-bottom: 1px solid ${secondaryDark};

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;

		width: 100%;
		margin: 0 0 25px 0;
	}

	& input {
		width: 100%;

		font-size: 16px;
		line-height: 125%;

		padding: 0 0 4px 0;
		margin: 0;
		background-color: transparent;
		-webkit-appearance: none;

		border: 0;

		color: ${(props) => props.textColor};
		position: relative;

		&:focus {
			outline: none;
		}
	}
`;

const ThankYouMessage = styled.div`
	font-size: 16px;
	line-height: 125%;

	& p {
		margin: 0 0 10px 0;
	}
`;

const SignUpButton = styled.button`
	color: ${secondaryDark};

	font-size: 16px;
	line-height: 125%;

	-webkit-appearance: none;

	border: 0;
	margin: 0;

	position: relative;

	cursor: pointer;

	&:focus {
		outline: none;
	}

	border: 1px solid ${secondaryDark};
	padding: 7px;

	transition: 150ms all ease;

	text-transform: capitalize;

	&:hover {
		color: ${tertiary};
		background-color: ${secondaryDark};
	}
`;

const PrivacyMessage = styled.div`
	width: 100%;
	max-width: 320px;

	& p {
		margin: 10px 0 0 0;
	}

	& a {
		border-bottom: 1px solid ${secondaryLight};

		&:hover {
			color: ${(props) => props.hoverColor};
			border-bottom: 1px solid ${(props) => props.hoverColor};
		}
	}

	@media (max-width: 1024px) {
		width: 100%;
	}

	@media (max-width: 600px) {
		max-width: 100%;
	}
`;

const GDPRContainer = styled.div`
	visibility: ${(props) => props.visibility};
`;

export const PopupForm = ({ primaryColor, data }) => {
	const [mailchimpMessage, setMailchimpMessage] = useState(null);
	const [mailchimpErrorMessage, setMailchimpErrorMessage] = useState(false);

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [formMessage, setFormMessage] = useState(null);

	const [isGDPRChecked, setIsGDPRChecked] = useState(true);

	// 1. via `.then`
	const handleSubmit = (e) => {
		e.preventDefault();

		addToMailchimp(email, { NAME: name }) // listFields are optional if you are only capturing the email address.
			.then((data) => {
				// I recommend setting data to React state
				// but you can do whatever you want (including ignoring this `then()` altogether)
				if (data.result === "error") {
					if (data.msg === "The email you entered is not valid.") {
						setFormMessage("Invalid Email");
						setName("");
					}

					if (data.msg.includes("already subscribed")) {
						setFormMessage(`Email is already subscribed`);
					}
				} else {
					setMailchimpMessage(
						"Thank you for signing up to the Christian Watson mailing list."
					);
					setEmail("");
					setName("");
					e.target.reset();
				}
			})
			.catch(() => {
				// unnecessary because Mailchimp only ever
				// returns a 200 status code
				// see below for how to handle errors
			});
	};

	useEffect(() => {
		if (mailchimpMessage !== null) {
			const resetFormVisbility = setTimeout(() => {
				setMailchimpMessage(null);
				setFormMessage(null);
			}, 5000);
			return () => clearTimeout(resetFormVisbility);
		}
	}, [mailchimpMessage]);

	return (
		<MailchimpFormContainer>
			<form onSubmit={(e) => handleSubmit(e)} className="mailchimp-signup-form">
				<div
					className="text"
					dangerouslySetInnerHTML={{
						__html: data.text.html,
					}}
				/>

				{mailchimpMessage === null ? (
					<InputContainer textColor={primaryColor} color={secondaryDark}>
						<div className="field">
							<input
								type="name"
								onChange={(e) => setName(e.target.value)}
								value={name}
								placeholder="Name"
								name="name"
								required
								autoComplete="name"
							/>
						</div>
						<div className="field">
							<input
								type="email"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								placeholder={`Email`}
								name="email"
								required
								autoComplete="email"
							/>
						</div>

						<GDPRContainer
							visibility={mailchimpMessage === null ? `visible` : `hidden`}
							className="gdpr-container"
						>
							<div className="gdpr-checkbox">
								<input
									type="checkbox"
									id="gdpr-checkbox"
									name="gdpr-checkbox"
									checked={isGDPRChecked}
									onChange={() => setIsGDPRChecked(!isGDPRChecked)}
									required
								/>
								<label htmlFor="gdpr-checkbox"></label>
							</div>

							<PrivacyMessage
								className="small-text"
								hoverColor={primaryColor}
								dangerouslySetInnerHTML={{
									__html: data.privacy_policy.html,
								}}
							/>
						</GDPRContainer>

						<SignUpButton type="submit">
							{data.call_to_action_text}
						</SignUpButton>

						{formMessage !== null && <p>{formMessage}</p>}
					</InputContainer>
				) : (
					<ThankYouMessage>{mailchimpMessage}</ThankYouMessage>
				)}
			</form>
		</MailchimpFormContainer>
	);
};
