import React from "react";
import { createGlobalStyle } from "styled-components";
import {
	secondaryDark,
	primaryLight,
	secondaryLight,
	tertiary,
} from "../utils/colors";

const GlobalStyle = createGlobalStyle`
	*:focus {
		outline: none;
	}


	html,
	html.loading {
		.splash-text-container h1,
		.splash-text-container p{
			opacity: 0;
			transition: 150ms opacity ease;
		}
	}

	html.wf-active,
	html.wf-inactive {
		.splash-text-container h1,
		.splash-text-container p{
			opacity: 1;
		}
	}


	body {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;

		font-weight: normal;
		font-style: normal;

		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0;

		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		-webkit-tap-highlight-color: transparent;

		color: ${secondaryDark};
		margin: 0;

		word-break: break-word;
		overflow-y: scroll;
	}

	input {
		border-radius: 0;
		background-color: transparent;
		color: ${secondaryDark};
	}

	textarea {
		background-color: transparent;
		color: ${secondaryDark};
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: normal;
		margin: 0;
	}

	/* H1 */
	h1 {
		font-size: 32px;

		line-height: 125%;
		letter-spacing: 0;

		@media (max-width: 1024px) {
			font-size: 22px;
		}
	}

	/* Large */
	h2,
	h3 {
		font-size: 22px;
		line-height: 125%;
		letter-spacing: 0;

		@media (max-width: 1024px) {
			font-size: 20px;
		}
  }


	.small-text,
	.small-text > p {
		font-size: 12px;
		line-height: 125%;
	}

	b,
	strong,
	.caps,
	.caps > h1,
	.caps > h2 {
		font-weight: normal;

		letter-spacing: 0.1em;
		text-transform: uppercase;
	}

	em {
		font-family: "MatterSQ-RegularItalic", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		font-weight: normal;
		font-style: normal;
	}

	/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

	video,
	img {
		width: 100%;
		margin: 0;

		display: block;
	}

	/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

	button {
		background-color: transparent;
		border: 0;
		-webkit-appearance: none;

		padding: 0;
		margin: 0;

		cursor: pointer;
		color: ${secondaryDark};

		transition: 150ms color ease;

		&:focus,
		&:hover {
			outline: none;
			color: ${secondaryLight};
		}
	}

	/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
	a {
		color: inherit;
		text-decoration: none;

		position: relative;
		transition: 150ms color ease;
	}

	a:visited {
		color: inherit;
	}

	a:hover,
	a:focus,
	a:active {
		color: ${secondaryLight};
	}

	@media (hover: none) and (pointer: coarse) {
		a:hover,
		a:focus,
		a:active {
				color: inherit !important;
		}
	}

	a:hover,
	a:active,
	a:focus {
		outline: 0;
	}

	/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

	p {
		margin: 1em 0;
	}

	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.center-text {
		text-align: center;
	}

	/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

	div {
		box-sizing: border-box;
	}

	/*--------------------------------------------------------------
  ## Margins
  --------------------------------------------------------------*/

	.margin-0 {
		margin: 0;
	}

	.margin-0-0-25-0 {
		margin: 0 0 25px 0;
	}

	/*--------------------------------------------------------------
  ## Form Placeholders
  --------------------------------------------------------------*/
	::-webkit-input-placeholder {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		color: ${secondaryDark};
	}
	::-moz-placeholder {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		color: ${secondaryDark};
	}
	:-ms-input-placeholder {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		color: ${secondaryDark};
	}
	:-moz-placeholder {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		color: ${secondaryDark};
	}
	::placeholder {
		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;
		color: ${secondaryDark};
	}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

	/*--------------------------------------------------------------
  ## General modals
	--------------------------------------------------------------*/

	.ReactModalPortal,
	.ReactModal__Overlay--after-open {
		z-index: 100000;
	}

	.ReactModal__Body--open {
		overflow-y: hidden;
	}

	.product-enquiry-modal,
	.delivery-modal {
		position: fixed;

		max-width: 720px;
		width: calc(100% - 40px);

		padding: 20px;

		top: 50%;
		left: 50%;
		right: auto;
		bottom: auto;

		transform: translate(-50%, -50%);

		background-color: ${tertiary};

		overflow-y: auto;

		@media (max-width: 600px) {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

      transform: none;

      width: auto;
		}
	}



	/*--------------------------------------------------------------
  ## Cookie container
  --------------------------------------------------------------*/

	.cookie-consent-container {
		position: fixed;
		right: 20px;
		bottom: 20px !important;

		z-index: 200;

		background-color: #f5f5f0;
		box-shadow: 0 5px 15px 0 rgba(90,50,40,0.15), 0 4px 8px 0 rgba(80,40,30,0.1);

		padding: 20px;

		font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
			sans-serif;

		font-size: 12px;
		line-height: 14px;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		width: 100%;
		max-width: 280px;

		& p {
			margin: 0;
		}

		& a {
			border-bottom: 1px solid #aaaaaa;
			color: #aaaaaa;

			&:hover {
				color: ${secondaryDark};
			}
		}

		& button {
			margin: 20px 0 0 0;

			text-transform: uppercase;
			border: 1px solid ${secondaryDark};
			padding: 5px;

			color: ${secondaryDark};

			&:hover {
				color: #f5f5f0;
				background-color: ${secondaryDark};
			}
		}

		@media (max-width: 768px) {
			justify-content: center;

			padding: 15px;

			& > div {
				width: 100%;
			}

			& button {
				margin: 15px 0 0 0;
			}
		}
	}



/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{

}

.ReactModalPortal-mailchimp,
.ReactModalPortal-gallery,
.ReactModalPortal-mobile-menu {
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
	background-color: rgb(255 255 255 / 25%);

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

/* .ReactModalPortal-mobile-menu .ReactModal__Overlay,
.ReactModalPortal-mailchimp .ReactModal__Overlay {
  background-color: #f5eadf !important;
}

.ReactModalPortal-gallery .ReactModal__Overlay {
  background-color: #fff !important;
} */

.ReactModal__Overlay--after-open{
  opacity: 1;

	position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
`;

export default GlobalStyle;
