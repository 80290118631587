import React, { useContext, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Media from "react-media";

// Context
import StoreContext from "../context/store";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

// Styles
import { secondaryDark } from "../utils/colors";

const HeaderContainer = styled.header`
	position: fixed;

	top: 0;
	left: 0;
	right: 0;

	z-index: 500;

	background-color: ${(props) => props.bgColor};

	${(props) =>
		props.bgColor === `transparent` &&
		`
			&:hover{
				background-color: #fff;
			}
	`};

	@media (max-width: 960px) {
		background-color: transparent;
	}

	@media (max-width: 768px) {
		height: ${(props) => props.mobileHeight};
		overflow-y: scroll;
	}
`;

const countQuantity = (lineItems) => {
	let quantity = 0;

	lineItems.forEach((item) => {
		quantity = quantity + item.quantity;
	});
	return quantity;
};

export const Header = ({ menuColor, windowWidth, location }) => {
	const context = useContext(StoreContext);
	const { checkout } = context;
	const [quantity, setQuantity] = useState(
		countQuantity(checkout ? checkout.lineItems : [])
	);

	useEffect(() => {
		setQuantity(countQuantity(checkout ? checkout.lineItems : []));
	}, [checkout]);

	// Menu State
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeSubMenu, setActiveSubMenu] = useState(null);

	const data = useStaticQuery(graphql`
		{
			prismicMenus {
				prismicId
				data {
					body {
						... on PrismicMenusBodyLink {
							id
							slice_type
							primary {
								link {
									document {
										... on PrismicBlog {
											id
											data {
												title {
													text
												}
											}
											url
										}
										... on PrismicCollection {
											id
											data {
												title {
													text
												}
											}
											url
										}
										... on PrismicFurniture {
											id
											data {
												title {
													text
												}
											}
											url
										}
										... on PrismicPage {
											id
											data {
												title {
													text
												}
											}
											url
										}
										... on PrismicBuyingGuide {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicCareGuide {
											id
											url
											data {
												title {
													text
												}
											}
										}
									}
								}
							}
							items {
								submenu_link {
									document {
										... on PrismicPage {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicCollection {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicBuyingGuides {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicCareGuides {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicLimitedEditionCollection {
											id
											url
											data {
												title {
													text
												}
											}
										}
									}
								}
							}
						}
						... on PrismicMenusBodySection {
							id
							slice_type
							primary {
								link_text
							}
							items {
								submenu_link {
									document {
										... on PrismicPage {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicCollection {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicBuyingGuides {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicCareGuides {
											id
											url
											data {
												title {
													text
												}
											}
										}
										... on PrismicLimitedEditionCollection {
											id
											url
											data {
												title {
													text
												}
											}
										}
									}
								}
							}
						}
						... on PrismicMenusBodyLinkWithAltTitle {
							id
							slice_type
							primary {
								link_text
								link {
									url
								}
							}
						}
					}
				}
			}
		}
	`);

	return (
		<HeaderContainer
			id="header"
			bgColor={menuColor}
			mobileHeight={isMenuOpen === true ? `100%` : `auto`}
			onMouseLeave={() => setActiveSubMenu(null)}
		>
			<Media
				queries={{ medium: "(max-width: 960px)" }}
				defaultMatches={{ medium: windowWidth === 960 }}
				render={() => (
					<MobileMenu
						menu={data.prismicMenus.data.body}
						quantity={quantity}
						location={location}
						textColor={menuColor === `#000` ? `#fff` : secondaryDark}
						setIsMenuOpen={setIsMenuOpen}
						isMenuOpen={isMenuOpen}
					/>
				)}
			/>

			<Media
				queries={{ medium: "(min-width: 961px)" }}
				defaultMatches={{ medium: windowWidth === 961 }}
				render={() => (
					<DesktopMenu
						menu={data.prismicMenus.data.body}
						quantity={quantity}
						location={location}
						textColor={menuColor === `#000` ? `#fff` : secondaryDark}
						activeSubMenu={activeSubMenu}
						setActiveSubMenu={setActiveSubMenu}
					/>
				)}
			/>
		</HeaderContainer>
	);
};
