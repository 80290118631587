import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { WLogo } from "../icons/w-logo";

const Nav = styled.nav`
	padding: 35px 30px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	& ol {
		margin: 0;
		padding: 0;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		& li {
			padding: 0 15px;
		}
	}

	@media (max-width: 800px) {
		padding: 30px 0;

		& a.logo {
			margin: auto;
		}

		& svg {
			width: 100%;
			order: 1;
			margin: 0 0 30px 0;
		}

		& ol {
			width: 100%;
			order: 2;

			&:first-of-type {
				margin: 0 0 20px 0;
			}
		}
	}
`;

export const DesktopFooter = ({ leftSideMenuItems, rightSideMenuItems }) => (
	<Nav role="navigation" aria-label="footer navigation">
		<ol>{leftSideMenuItems}</ol>
		<Link to={`/`} className="logo">
			<WLogo />
		</Link>
		<ol>{rightSideMenuItems}</ol>
	</Nav>
);
