import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Styles
import { secondaryLight, secondaryDark } from "../utils/colors";

// Components
import { SearchProducts } from "./search-products";

const Nav = styled.nav`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	& .logo {
		letter-spacing: 0.1em;

		& a:hover {
			color: inherit;
		}
	}

	& ol {
		margin: 0;
		padding: 0;
	}

	ol.menu-navigation {
		width: 100%;
		background-color: #fff;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		padding: 17px 15px;

		line-height: 1;
	}

	ol.menu-container {
		display: ${(props) => props.isMenuOpen};
		background-color: ${(props) => props.bgColor};

		width: 100%;
		// max-width: 500px;

		padding: 19px 15px;

		& li {
			line-height: 50px;
		}
	}
`;

const LinkWithSubMenu = styled.li`
	& button {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		width: 100%;
		line-height: 50px;
	}
	& .link-submenu {
		display: ${(props) => props.displaySubMenu};
		margin: 0 0 0 30px;

		& li {
			line-height: 30px;
			color: ${secondaryDark};

			& a:hover {
				color: ${secondaryLight};
			}
		}
	}
`;

export const MobileMenu = ({
	menu,
	quantity,
	location,
	setIsMenuOpen,
	isMenuOpen,
}) => {
	const [activeSubMenu, setActiveSubMenu] = useState(null);

	useEffect(() => {
		setIsMenuOpen(false);
	}, [location]);

	useEffect(() => {
		if (isMenuOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [isMenuOpen]);

	const menuItems = menu.map((menuItem, index) => {
		if (menuItem.slice_type === "section") {
			const subMenu = menuItem.items
				.filter((item) => item.submenu_link.document !== null)
				.map((subMenuItem, index) => (
					<li
						key={`sub_navigation_item_${index}_${subMenuItem.submenu_link.document.id}`}
					>
						<Link
							to={subMenuItem.submenu_link.document.url}
							aria-label={subMenuItem.submenu_link.document.data.title.text}
							partiallyActive
							activeClassName="current"
						>
							{subMenuItem.submenu_link.document.data.title.text}
						</Link>
					</li>
				));

			return (
				<LinkWithSubMenu
					key={`navigation_item_${index}`}
					displaySubMenu={activeSubMenu === menuItem.id ? `block` : `none`}
				>
					<button
						onClick={() => {
							if (activeSubMenu === menuItem.id) {
								setActiveSubMenu(null);
							} else {
								setActiveSubMenu(menuItem.id);
							}
						}}
					>
						{menuItem.primary.link_text}{" "}
						<span>{activeSubMenu ? `–` : `+`}</span>
					</button>

					{subMenu.length >= 1 && <ul className="link-submenu">{subMenu}</ul>}
				</LinkWithSubMenu>
			);
		}

		if (menuItem.slice_type === "link_with_alt_title") {
			return (
				<li key={`navigation_item_${index}`}>
					<Link
						to={menuItem.primary.link.url}
						aria-label={menuItem.primary.link_text}
						partiallyActive
						activeClassName="current"
					>
						{menuItem.primary.link_text}
					</Link>
				</li>
			);
		}

		if (menuItem.slice_type === "link") {
			return (
				<li key={`navigation_item_${index}`}>
					<Link
						to={menuItem.primary.link.document.url}
						aria-label={menuItem.primary.link.document.data.title.text}
						partiallyActive
						activeClassName="current"
					>
						{menuItem.primary.link.document.data.title.text}
					</Link>
				</li>
			);
		}
	});

	return (
		<Nav
			role="navigation"
			aria-label="main navigation"
			isMenuOpen={isMenuOpen === true ? `block` : `none`}
			bgColor={isMenuOpen === true ? `#ECECE7` : `transparent`}
		>
			<ol className="menu-navigation">
				<li className="logo">
					<Link aria-label="home" to={`/`}>
						CHRISTIAN WATSON
					</Link>
				</li>

				<li className="menu-toggle">
					<button onClick={() => setIsMenuOpen(!isMenuOpen)}>
						{isMenuOpen
							? `Close`
							: `Menu ${quantity >= 1 ? `( ${quantity} )` : ``}`}
					</button>
				</li>
			</ol>

			<ol className="menu-container">
				<li>
					<Link
						aria-label="basket"
						to="/basket"
						partiallyActive
						activeClassName="current"
					>
						Basket {quantity >= 1 && `( ${quantity} )`}
					</Link>
				</li>

				{menuItems}
				<li>
					<SearchProducts isMobileSearch={true} location={location} />
				</li>

				<li>
					<Link
						aria-label="account"
						to="/account"
						partiallyActive
						activeClassName="current"
					>
						Account
					</Link>
				</li>
			</ol>
		</Nav>
	);
};
