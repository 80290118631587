// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-add-address-js": () => import("./../../../src/pages/account/add-address.js" /* webpackChunkName: "component---src-pages-account-add-address-js" */),
  "component---src-pages-account-addresses-js": () => import("./../../../src/pages/account/addresses.js" /* webpackChunkName: "component---src-pages-account-addresses-js" */),
  "component---src-pages-account-buying-guides-js": () => import("./../../../src/pages/account/buying-guides.js" /* webpackChunkName: "component---src-pages-account-buying-guides-js" */),
  "component---src-pages-account-care-guides-js": () => import("./../../../src/pages/account/care-guides.js" /* webpackChunkName: "component---src-pages-account-care-guides-js" */),
  "component---src-pages-account-edit-address-js": () => import("./../../../src/pages/account/edit-address.js" /* webpackChunkName: "component---src-pages-account-edit-address-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-logout-js": () => import("./../../../src/pages/account/logout.js" /* webpackChunkName: "component---src-pages-account-logout-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-trade-index-js": () => import("./../../../src/pages/account/trade/index.js" /* webpackChunkName: "component---src-pages-account-trade-index-js" */),
  "component---src-pages-account-trade-materials-library-js": () => import("./../../../src/pages/account/trade/materials-library.js" /* webpackChunkName: "component---src-pages-account-trade-materials-library-js" */),
  "component---src-pages-account-trade-products-js": () => import("./../../../src/pages/account/trade/products.js" /* webpackChunkName: "component---src-pages-account-trade-products-js" */),
  "component---src-pages-activate-customer-js": () => import("./../../../src/pages/activate-customer.js" /* webpackChunkName: "component---src-pages-activate-customer-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-buying-guides-js": () => import("./../../../src/pages/buying-guides.js" /* webpackChunkName: "component---src-pages-buying-guides-js" */),
  "component---src-pages-care-guides-js": () => import("./../../../src/pages/care-guides.js" /* webpackChunkName: "component---src-pages-care-guides-js" */),
  "component---src-pages-furniture-js": () => import("./../../../src/pages/furniture.js" /* webpackChunkName: "component---src-pages-furniture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-order-confirmation-js": () => import("./../../../src/pages/order-confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-buying-guide-js": () => import("./../../../src/templates/buying-guide.js" /* webpackChunkName: "component---src-templates-buying-guide-js" */),
  "component---src-templates-care-guide-js": () => import("./../../../src/templates/care-guide.js" /* webpackChunkName: "component---src-templates-care-guide-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-limited-edition-collection-js": () => import("./../../../src/templates/limited-edition-collection.js" /* webpackChunkName: "component---src-templates-limited-edition-collection-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

