import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Styles
import { tertiary } from "../utils/colors";

// Components
import { DesktopFooter } from "../footer/desktop-footer";

const FooterContainer = styled.footer`
	position: relative;

	width: 100%;

	z-index: 1;
	background-color: ${(props) => props.bgColor};

	// margin: ${(props) => props.margin};

	// @media (max-width: 768px) {
	// 	margin: 60px 0 0 0;
	// }
`;

export const Footer = ({ location, footerColor }) => {
	const data = useStaticQuery(graphql`
		{
			prismicMenus {
				prismicId
				data {
					links {
						left_side_link {
							document {
								... on PrismicBlog {
									id
									data {
										title {
											text
										}
									}
									uid
								}
								... on PrismicBuyingGuides {
									id
									uid
									data {
										title {
											text
										}
									}
								}
								... on PrismicCareGuides {
									id
									uid
									data {
										title {
											text
										}
									}
								}
								... on PrismicPage {
									id
									data {
										title {
											text
										}
									}
									uid
								}
								... on PrismicPolicyPage {
									id
									data {
										title {
											text
										}
									}
									uid
								}
							}
						}
					}
					right_side_links {
						right_side_link {
							document {
								... on PrismicBlog {
									id
									data {
										title {
											text
										}
									}
									uid
								}
								... on PrismicPage {
									id
									data {
										title {
											text
										}
									}
									uid
								}
								... on PrismicPolicyPage {
									id
									data {
										title {
											text
										}
									}
									uid
								}
							}
						}
					}
				}
			}
		}
	`);

	const leftSideMenuItems = data.prismicMenus.data.links.map(
		(menuItem, index) => (
			<li key={`navigation_item_${index}`} className="small-text">
				<Link
					to={`/${menuItem.left_side_link.document.uid}`}
					aria-label={menuItem.left_side_link.document.data.title.text}
				>
					{menuItem.left_side_link.document.data.title.text}
				</Link>
			</li>
		)
	);

	const rightSideMenuItems = data.prismicMenus.data.right_side_links.map(
		(menuItem, index) => (
			<li key={`navigation_item_${index}`} className="small-text">
				<Link
					to={`/${menuItem.right_side_link.document.uid}`}
					aria-label={menuItem.right_side_link.document.data.title.text}
				>
					{menuItem.right_side_link.document.data.title.text}
				</Link>
			</li>
		)
	);

	return (
		<FooterContainer
			// margin={location.includes(`account`) ? `0` : `100px 0 0 0`}
			// bgColor={location.includes(`account`) ? `#fff` : tertiary}
			bgColor={footerColor}
		>
			<DesktopFooter
				data={data}
				leftSideMenuItems={leftSideMenuItems}
				rightSideMenuItems={rightSideMenuItems}
			/>
		</FooterContainer>
	);
};
