import React from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Styles
import { secondaryDark } from "../utils/colors";

// Icons
import { SplashIcon } from "../icons/splash-logo";
import { StraplineIcon } from "../icons/strapline";

const SplashWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	width: 100vw;
	height: ${(props) => props.height};

	transition: 1000ms clip-path ease-in-out;
	z-index: 1;

	clip-path: polygon(
		0 0,
		100% 0,
		100% ${(props) => (props.slide ? "0%" : "100%")},
		0% ${(props) => (props.slide ? "0%" : "100%")}
	);
`;

const SplashInnerWrapper = styled.div`
	transition: 750ms background-color ease-in-out;
	background-color: ${(props) => (props.hide ? "#fff" : `#000`)};

	width: 100%;
	height: 100%;
`;

const Logo = styled.div`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	position: absolute;

	width: 100%;
`;

const OneLiner = styled.div`
	position: absolute;
	bottom: 100px;
	width: 100%;

	@media (max-width: 500px) {
		bottom: 40px;
	}
`;

export const Splash = ({ hide, slide }) => {
	const height = use100vh();

	return (
		<SplashWrapper
			slide={slide}
			height={`${height}px`}
			className="splash-text-container"
		>
			<SplashInnerWrapper hide={hide}>
				<Logo>
					<SplashIcon fill={hide === true ? secondaryDark : "#fff"} />
				</Logo>
				<OneLiner>
					<StraplineIcon fill={hide === true ? secondaryDark : "#fff"} />
				</OneLiner>
			</SplashInnerWrapper>
		</SplashWrapper>
	);
};
