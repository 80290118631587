import React from "react";
import styled from "styled-components";

const AspectRatioContainer = styled.div`
	position: relative;
	overflow: hidden;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ${(props) => props.padding}%;
		background-color: ${(props) =>
			props.background ? props.background : `#f8f8f8`};
	}

	& > .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;

export const AspectRatioImageContainer = ({
	children,
	image,
	padding,
	background,
}) => {
	if (image === null) {
		return (
			<AspectRatioContainer
				padding={padding}
				background={background}
				className="aspect-ratio-container"
			>
				<div className="content">{children}</div>
			</AspectRatioContainer>
		);
	} else {
		const aspectRatioWidth = image.dimensions.width / image.fluid.aspectRatio;
		const aspectRatioHeight = image.dimensions.height / image.fluid.aspectRatio;
		const aspectRatio = (aspectRatioHeight / aspectRatioWidth) * 100;

		return (
			<AspectRatioContainer
				padding={aspectRatio}
				background={background}
				className="aspect-ratio-container"
			>
				<div className="content">{children}</div>
			</AspectRatioContainer>
		);
	}
};
